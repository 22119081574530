import { useContext } from "react";

import StyledContentPanel from "../components/StyledContentPanel";
import { AppContext, MenuScreen } from "../App";
import StyledButton from "../components/StyledButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { useTranslation } from "react-i18next";

const LanguageScreen: React.FC = () => {
    const { i18n, t } = useTranslation();
    const { setMenuScreen } = useContext(AppContext);

    const chooseBulgarian = () => {
        i18n.changeLanguage('bg');
        setMenuScreen(MenuScreen.MainMenu);
    };

    const chooseEnglish = () => {
        i18n.changeLanguage('en');
        setMenuScreen(MenuScreen.MainMenu);
    };

    const chooseGerman = () => {
        i18n.changeLanguage('de');
        setMenuScreen(MenuScreen.MainMenu);
    };

    return (
        <div style={{ padding: '20px'}}>
            <StyledContentPanel style={{ marginBottom: '20px' }}>
                <h1>{t('menu_language_title')}</h1>
            </StyledContentPanel>
            <StyledButton onClick={() => setMenuScreen(MenuScreen.MainMenu)}>
                <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faArrowLeft} /></span>
                {t('button_back')}
            </StyledButton>
            <StyledButton onClick={chooseBulgarian}>
                Български
            </StyledButton>
            <StyledButton onClick={chooseEnglish}>
                English
            </StyledButton>
            <StyledButton onClick={chooseGerman}>
                Deutsch
            </StyledButton>
        </div>
    );
};

export default LanguageScreen;